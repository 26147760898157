import React from 'react';
import { EditBase, ReferenceInput, required, SelectInput, SimpleForm, TextInput, TopToolbar, useRecordContext } from 'react-admin';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Users / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const ProjectEdit = (props: any) => (

<EditBase {...props}>
  <>
  <Breadcrumb />
  {/* Utilisation d'une grille à deux colonnes */}
  <Grid container spacing={2}>
    {/* Colonne principale avec les projets associés */}
    <Grid item xs={8}>
      <Card>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Typography variant="h6" gutterBottom>
              Associated Users
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>


    <Grid item xs={4}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Project Details
          </Typography>
          <SimpleForm>
            <TextInput source="id" disabled />
            <ReferenceInput label="Billing Account" source="billingAccountId" reference="billing-accounts" >
              <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="folderId" disabled />
            <TextInput source="projectDatawareHouseId" disabled />
            <TextInput source="accountEmail" disabled />
            <TextInput source="region" disabled />
          </SimpleForm>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  
  </>
</EditBase>


);