import { auth } from '../Services/authProvider';

export interface IParams {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  data?: any;
  download?: boolean;
  api?: "CORE" | "NOTIF";
}

export interface IIint {
  method: string;
  headers: any;
  body?: any;
}

export const mock = async <T>(params: IParams): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const mockedData: any = params
      resolve(mockedData.data);
    }, 1000);

    if(params.data && params.data.error){
      reject({ message: "An error occured" });
    }

  })
}
const r = async <T>(params: IParams): Promise<T> => {
  return new Promise(async (resolve, reject) => {

    const userToken = await auth.currentUser?.getIdToken() || localStorage.getItem('token');
    
    (async () => {
      if (params.data && params.method == "GET") {
        Object.keys(params.data).forEach((k) => {
          if (params.data[k] == null) {
            delete params.data[k];
          }
        });
      }

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");


      myHeaders.append("Authorization", `Bearer ${userToken}`);

      const myInit: IIint = {
        method: params.method.toUpperCase(),
        headers: myHeaders,
      };

      if (params.data && ["POST", "PUT", "PATCH"].includes(params.method)) {
        myInit.body = JSON.stringify(params.data);
      } else if (params.data && ["GET"].includes(params.method)) {
        params.url = `${params.url}?${new URLSearchParams(
          params.data
        ).toString()}`;
      }

      const myRequest = new Request(params.url, myInit);

      fetch(myRequest, myInit)
        .then((response) => {
          if (params.download) return response.blob();

          if (response.ok) {
            const contentType = response.headers.get("content-type");

            if (
              contentType?.includes("application/json") ||
              contentType?.includes("text/html; charset=UTF-8")
            ) {
              return response.json();
            } else {
              return response.text();
            }
          }

          throw response;
        })
        .then((data) => {
          resolve(data);
        })
        .catch(async (error: any) => {
          if (typeof error.text === "function") {
            const errorResult = await error.text();

            try {
              const errorResultJson = JSON.parse(errorResult);

              reject(errorResultJson);

            } catch (e) {
              reject({
                code: error.statusCode,
                message: error.message ? error.message : "An error occured",
              });
            }


          } else {
            reject(error);
          }
        });
    })();
  });
};

export default r;
