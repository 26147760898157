import React, { useState } from 'react';
import { EditBase, TopToolbar, SimpleForm, TextInput, SelectInput, SelectArrayInput, ReferenceManyField, Datagrid, TextField, EditButton, DeleteButton, useRecordContext, required } from 'react-admin';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { roleChoices, subscriptionStatusChoices } from '../../Services/users';
import ProjectUserModalCreate from './UserProjects/ProjectUserModalCreate';


const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Users / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const UserEdit = (props: any) => {
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
  <EditBase {...props}>
    <>
    <Breadcrumb />
    {/* Utilisation d'une grille à deux colonnes */}
    <Grid container spacing={2}>
      {/* Colonne principale avec les projets associés */}
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Associated Projects
              </Typography>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                    +
              </Button>
            </div>

            <ReferenceManyField
              label="Projects"
              reference="project-users"
              target="userId"
              sort={{ field: 'project.name', order: 'ASC' }}
            >
              <Datagrid>
                <TextField source="project.name" label="Project" />
                <TextField source="role" label="Role" />
                <EditButton />
                <DeleteButton />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
      </Grid>

      {/* Colonne secondaire avec le formulaire utilisateur */}
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              User Details
            </Typography>
            <SimpleForm>
              <TextInput source="id" disabled />
              <TextInput source="firstname" validate={required()} />
              <TextInput source="lastname" validate={required()} />
              <TextInput source="name" />
              <TextInput source="email" disabled />
              <TextInput source="phone" />
              <TextInput source="companyName" />
              <TextInput source="companyType" />
              <TextInput source="situation" />
              <TextInput source="city" />
              <TextInput source="zipCode" />
              <TextInput source="adress" />
              <TextInput source="country" />
              <SelectArrayInput 
                source="roles" 
                choices={roleChoices} 
                optionText="name" 
                optionValue="id" 
              />
              <SelectInput 
                source="subscriptionStatus" 
                choices={subscriptionStatusChoices} 
                optionText="name" 
                optionValue="id" 
              />
            </SimpleForm>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <ProjectUserModalCreate open={open} handleClose={handleClose} />
    </>
  </EditBase>
)};
